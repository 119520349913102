import Vue from 'vue';
import ErrorModel from '@/api/models/ErrorModel';
import { getAllCategories, updateCategories } from '@/domains/pd-admin/api/zam-website/categories';
import { CategoryDataRowInterface, ReorderedInfoInterface } from '@/domains/pd-admin/types/zam-website/categories';
import { onBeforeMount, reactive, ref } from '@vue/composition-api';

const useManageCategories = () => {
  const isLoading = ref(false);

  const originalCategories = reactive<Record<string, CategoryDataRowInterface[]>>({
    list: [],
  });

  const displayedCategories = reactive<Record<string, CategoryDataRowInterface[]>>({
    list: [],
  });

  onBeforeMount(async () => {
    isLoading.value = true;
    const res = await getAllCategories();
    if (res instanceof ErrorModel || !res.categories) {
      isLoading.value = false;
      return;
    }
    originalCategories.list = [...res.categories.apps_by_category];
    displayedCategories.list = [...originalCategories.list];
    isLoading.value = false;
  });

  const onCategoryReorder = async (event: Record<string, HTMLElement | DOMRect | boolean>) => {
    const updatedCategories: ReorderedInfoInterface[] = displayedCategories.list.map((cat, index) => ({
      app_category_id: cat.id,
      order: index + 1,
    }));

    const res = await updateCategories({
      top_categories: updatedCategories,
    });

    if (res instanceof ErrorModel || res.status !== 'success') {
      Vue.$toast.error('Could not update category order');
      return false;
    }
    Vue.$toast.success('Updated category order successfully');
    return true;
  };

  return {
    originalCategories,
    displayedCategories,
    isLoading,
    onCategoryReorder,
  };
};

export default useManageCategories;
