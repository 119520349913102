export interface ManageFAQsStateInterface {
  id: number;
  questionAR: string;
  questionEN: string;
  questionNumber: string;
  answerAR: string;
  answerEN: string;
}

export enum ManageFAQsInputPropertiesEnum {
  QuestionAR = 'questionAR',
  QuestionEN = 'questionEN',
  QuestionNumber = 'questionNumber',
  AnswerAR = 'answerAR',
  AnswerEN = 'answerEN',
}

export interface FAQsDataRowInterface {
  id: number;
  question: {
    ar: string;
    en: string;
  };
  answer: {
    ar: string;
    en: string;
  };
  order: number;
}

export interface FAQsInfoInterface {
  id: number | null;
  question_en: string;
  question_ar: string;
  answer_en: string;
  answer_ar: string;
  order: number;
}
