import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import { ZidLoader, ZidTable, ZidTableRowGroup, ZidTableHeader, ZidTableRow, ZidTableCell } from '@zidsa/ui';
import draggable from 'vuedraggable';
import useManageCategories from '@/domains/pd-admin/helpers/zam-website/categories/useManageCategories';

export default defineComponent({
  components: {
    BaseText,
    ZidLoader,
    draggable,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    return {
      ...useManageCategories(),
    };
  },
});
