import BaseModel from '@/api/models/BaseModel';
import { FAQsDataRowInterface } from '../../types/zam-website/faqs/faqs';

class AllFAQsResponseModel extends BaseModel {
  public faqs: FAQsDataRowInterface[];
  constructor(arg: any) {
    super(arg);
    this.faqs = arg.faqs;
  }
}

export default AllFAQsResponseModel;
