import BaseModel from '@/api/models/BaseModel';
import { CategoryDataRowInterface } from '../../types/zam-website/categories';

class AllCategoriesResponseModel extends BaseModel {
  public categories: {
    apps_by_category: CategoryDataRowInterface[];
  };
  constructor(arg: any) {
    super(arg);
    this.categories = arg.categories;
  }
}

export default AllCategoriesResponseModel;
