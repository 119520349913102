import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import ErrorModel from '@/api/models/ErrorModel';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import {
  ZidButton,
  ZidLoader,
  ZidInput,
  ZidTextarea,
  ZidIcon,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import useManageFAQs from '@/domains/pd-admin/helpers/zam-website/faqs/useManageFAQs';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { FAQsDataRowInterface, FAQsInfoInterface } from '@/domains/pd-admin/types/zam-website/faqs/faqs';
import { deleteFAQ, updateOrCreateFAQ } from '@/domains/pd-admin/api/zam-website/faqs';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidLoader,
    BaseIcon,
    EditIcon,
    DeleteIcon,
    TabMultiLanguage,
    ZidInput,
    ZidIcon,
    ZidTextarea,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    const isSaveLoading = ref(false);

    const {
      manageFAQsState,
      v$,
      isLoadingFAQsList,
      showAddOrEditFAQ,
      FAQsList,
      isEditMode,
      showMessageQuestionField,
      ManageFAQsInputPropertiesEnum,
      onInputDataChange,
      fetchFAQs,
    } = useManageFAQs();

    const resetFAQsState = () => {
      manageFAQsState.questionAR = '';
      manageFAQsState.questionEN = '';
      manageFAQsState.questionNumber = '';
      manageFAQsState.answerAR = '';
      manageFAQsState.answerEN = '';
    };

    const onDeleteFAQ = async (faq: FAQsDataRowInterface) => {
      const res = await deleteFAQ(faq.id);
      if (res instanceof ErrorModel || res.status !== 'success') {
        Vue.$toast.error('Failed to delete FAQ');
        return;
      }
      const FAQIndex = FAQsList.list.findIndex((FAQ) => FAQ.id === faq.id);
      if (FAQIndex !== -1) {
        FAQsList.list.splice(FAQIndex, 1);
      }
      Vue.$toast.success('Deleted FAQ successfully');
    };

    const onEditFAQ = async (faq: FAQsDataRowInterface) => {
      if (showAddOrEditFAQ.value) {
        onCancelNewFAQBtnClicked();
      }
      showAddOrEditFAQ.value = true;
      manageFAQsState.id = faq.id;
      manageFAQsState.questionEN = faq.question.en;
      manageFAQsState.questionAR = faq.question.ar;
      manageFAQsState.answerEN = faq.answer.en;
      manageFAQsState.answerAR = faq.answer.ar;
      manageFAQsState.questionNumber = `${faq.order}`;
      isEditMode.value = true;
    };

    const onAddNewFAQBtnClicked = () => {
      resetFAQsState();
      isEditMode.value = false;
      showAddOrEditFAQ.value = true;
    };

    const onCancelNewFAQBtnClicked = () => {
      showAddOrEditFAQ.value = false;
      resetFAQsState();
      isEditMode.value = false;
    };

    const onSaveNewFAQBtnClicked = async () => {
      isSaveLoading.value = true;

      showAddOrEditFAQ.value = false;

      const faqInfo: FAQsInfoInterface = {
        id: isEditMode.value ? manageFAQsState.id : 0,
        question_en: manageFAQsState.questionEN,
        question_ar: manageFAQsState.questionAR,
        answer_en: manageFAQsState.answerEN,
        answer_ar: manageFAQsState.answerAR,
        order: Number(manageFAQsState.questionNumber),
      };

      const res = await updateOrCreateFAQ(faqInfo);

      if (res instanceof ErrorModel || res.status !== 'success') {
        Vue.$toast.error('Failed update FAQ');
        return;
      }

      Vue.$toast.success('Updated FAQ successfully');
      await fetchFAQs();

      manageFAQsState.id = -1;
      resetFAQsState();
      isEditMode.value = false;
      isSaveLoading.value = false;
    };

    return {
      manageFAQsState,
      v$,
      isLoadingFAQsList,
      showAddOrEditFAQ,
      FAQsList,
      isEditMode,
      showMessageQuestionField,
      ManageFAQsInputPropertiesEnum,
      isSaveLoading,
      onInputDataChange,
      onDeleteFAQ,
      onEditFAQ,
      onAddNewFAQBtnClicked,
      onSaveNewFAQBtnClicked,
      onCancelNewFAQBtnClicked,
    };
  },
});
