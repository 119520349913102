import { reactive, onBeforeMount, ref, computed } from '@vue/composition-api';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {
  FAQsDataRowInterface,
  ManageFAQsInputPropertiesEnum,
  ManageFAQsStateInterface,
} from '@/domains/pd-admin/types/zam-website/faqs/faqs';
import { POSITIVE_NUMBER } from '@/helpers/regex/numbers';
import ErrorModel from '@/api/models/ErrorModel';
import { getAllFAQs } from '@/domains/pd-admin/api/zam-website/faqs';

const useManageFAQs = () => {
  const isLoadingFAQsList = ref(false);
  const showAddOrEditFAQ = ref(false);
  const isEditMode = ref(false);

  const FAQsList = reactive<Record<string, FAQsDataRowInterface[]>>({ list: [] });

  onBeforeMount(async () => {
    await fetchFAQs();
  });

  const fetchFAQs = async () => {
    isLoadingFAQsList.value = true;
    const FAQRes = await getAllFAQs();
    if (FAQRes instanceof ErrorModel || !FAQRes.faqs) {
      return;
    }
    FAQsList.list = [...FAQRes.faqs.sort((a, b) => a.order - b.order)];
    isLoadingFAQsList.value = false;
  };

  const manageFAQsState = reactive<ManageFAQsStateInterface>({
    id: -1,
    questionAR: '',
    questionEN: '',
    questionNumber: '',
    answerAR: '',
    answerEN: '',
  });

  //eslint-disable-next-line
  const FAQ_ARABIC_TEXT_WITH_CUSTOMIZATION: RegExp =
    /^[\u0621-\u064A0-9 _|,|\n|؟|\-|'|.|"|:|!|@|#|/|\\\\|_|[|\]|)|(|*]+$/;

  //eslint-disable-next-line
  const FAQ_ENGLISH_TEXT_WITH_CUSTOMIZATION: RegExp =
    /^[A-Za-z0-9 _]*[A-Za-z]+[A-Za-z0-9 _|,|\n|?|\-|'|.|"|:|!|@|#|/|\\\\|_|[|\]|(|)|*]*$/;

  const rules = {
    id: {},
    questionAR: {
      required,
      questionAR: helpers.withMessage('من فضلك ادخل السؤال باللغة العربية', (value: string) => {
        return FAQ_ARABIC_TEXT_WITH_CUSTOMIZATION.test(value);
      }),
    },
    questionEN: {
      required,
      questionEN: helpers.withMessage('please enter the question in English', (value: string) => {
        return FAQ_ENGLISH_TEXT_WITH_CUSTOMIZATION.test(value);
      }),
    },
    questionNumber: {
      required,
      questionNumber: helpers.withMessage('please enter the order of question', (value: string) => {
        return POSITIVE_NUMBER.test(value);
      }),
    },
    answerAR: {
      required,
      answerAR: helpers.withMessage('من فضلك ادخل الاجابة باللغة العربية', (value: string) => {
        return FAQ_ARABIC_TEXT_WITH_CUSTOMIZATION.test(value);
      }),
    },
    answerEN: {
      required,
      answerEN: helpers.withMessage('please enter the answer in English', (value: string) => {
        return FAQ_ENGLISH_TEXT_WITH_CUSTOMIZATION.test(value);
      }),
    },
  };

  const v$ = useVuelidate(rules, manageFAQsState);

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: ManageFAQsInputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    manageFAQsState[dataProperty] = changedValue.value;
  };

  const showMessageQuestionField = computed(() => {
    return (
      (v$.value.questionAR.$dirty || v$.value.questionEN.$dirty) &&
      (v$.value.questionAR.$invalid || v$.value.questionEN.$invalid)
    );
  });

  return {
    manageFAQsState,
    v$,
    isLoadingFAQsList,
    showAddOrEditFAQ,
    FAQsList,
    isEditMode,
    showMessageQuestionField,
    ManageFAQsInputPropertiesEnum,
    onInputDataChange,
    fetchFAQs,
  };
};

export default useManageFAQs;
