import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import AllCategoriesResponseModel from '../../models/zam-website/all-categories-response-model';
import { ReorderedInfoInterface } from '../../types/zam-website/categories';

const ENDPOINT_URL = '/market';

export const getAllCategories = (): Promise<AllCategoriesResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/public/categories`)
    .then((response) => new AllCategoriesResponseModel(response))
    .catch((error) => error);
};

export const updateCategories = (
  reorderedCategories: Record<string, ReorderedInfoInterface[]>,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/admin/categories`, reorderedCategories)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
