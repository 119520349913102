import { render, staticRenderFns } from "./ManageFAQs.vue?vue&type=template&id=444ad535&scoped=true&"
import script from "./manageFAQs.ts?vue&type=script&lang=ts&"
export * from "./manageFAQs.ts?vue&type=script&lang=ts&"
import style0 from "./manageFAQs.scss?vue&type=style&index=0&id=444ad535&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "444ad535",
  null
  
)

export default component.exports