import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import AllFAQsResponseModel from '../../models/zam-website/all-faqs-response-model';
import { FAQsInfoInterface } from '../../types/zam-website/faqs/faqs';

const ENDPOINT_URL = '/market';

export const getAllFAQs = (): Promise<AllFAQsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/public/faqs?no_locale`)
    .then((response) => new AllFAQsResponseModel(response))
    .catch((error) => error);
};

export const deleteFAQ = (FAQID: number): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/admin/faqs?id=${FAQID}`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const updateOrCreateFAQ = (FAQ: FAQsInfoInterface): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/admin/faqs`, FAQ)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
